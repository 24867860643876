@font-face {
  font-family: MainFont;
  src: url("MYRIADPRO-REGULAR.65f815d0.otf");
}

@font-face {
  font-family: MainFontLight;
  src: url("MyriadPro-Light.147a75ec.otf");
}
/*# sourceMappingURL=index.44253da5.css.map */
